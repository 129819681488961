import Vue from 'vue';

Vue.filter('toCurrency', function (value) {
  let numValue = 0;
  if (typeof value !== "number" && !isNaN(value)) {
    numValue = parseFloat(value);
  } else {
    numValue = value;
  }
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });
  return formatter.format(numValue);
});