
export default {
  name: 'HeaderComponent',
  data() {
    return {
      items: [
        { title: 'Dashboard', icon: 'mdi-home-outline', active: false, redirect: "/admin/home" },
        { title: 'Terms and Conditions', icon: 'mdi-book-account-outline', active: false, redirect: "/admin/terms" },
        { title: 'Product packages', icon: 'mdi-package-variant-closed', active: false, redirect: "/admin/packages" },
        { title: 'Log out', icon: 'mdi-logout', active: false, redirect: "/logout" },
      ],
      right: null,
    }
  },
  mounted() {
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].redirect === this.$route.path) {
        this.items[i].active = true
      }
    }
  },
  methods: {
    selectOption(index) {
      for (let i = 0; i < this.items.length; i++) {
        this.items[i].active = false;
      }
      for (let i = 0; i < this.items.length; i++) {
        if (i === index) {
          this.items[i].active = true
        }
      }
    }
  }
}
