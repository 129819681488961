import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _63d732e7 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _766ec1c5 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _601e46dd = () => interopDefault(import('../pages/admin/home/index.vue' /* webpackChunkName: "pages/admin/home/index" */))
const _9d62382a = () => interopDefault(import('../pages/admin/packages/index.vue' /* webpackChunkName: "pages/admin/packages/index" */))
const _86b01742 = () => interopDefault(import('../pages/admin/products.vue' /* webpackChunkName: "pages/admin/products" */))
const _0dd4f11c = () => interopDefault(import('../pages/admin/terms.vue' /* webpackChunkName: "pages/admin/terms" */))
const _177264a2 = () => interopDefault(import('../pages/packages/confirmation/index.vue' /* webpackChunkName: "pages/packages/confirmation/index" */))
const _044649eb = () => interopDefault(import('../pages/admin/home/home.js' /* webpackChunkName: "pages/admin/home/home" */))
const _835bf1aa = () => interopDefault(import('../pages/admin/packages/packages.js' /* webpackChunkName: "pages/admin/packages/packages" */))
const _773a2927 = () => interopDefault(import('../pages/packages/confirmation/confirmation.js' /* webpackChunkName: "pages/packages/confirmation/confirmation" */))
const _14c51932 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7dad99b8 = () => interopDefault(import('../pages/packages/pdfGenerate/_paymentID/index.vue' /* webpackChunkName: "pages/packages/pdfGenerate/_paymentID/index" */))
const _56522b74 = () => interopDefault(import('../pages/packages/_name/index.vue' /* webpackChunkName: "pages/packages/_name/index" */))
const _0c21a2f1 = () => interopDefault(import('../pages/packages/_name/_name.js' /* webpackChunkName: "pages/packages/_name/_name" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _63d732e7,
    name: "admin"
  }, {
    path: "/logout",
    component: _766ec1c5,
    name: "logout"
  }, {
    path: "/admin/home",
    component: _601e46dd,
    name: "admin-home"
  }, {
    path: "/admin/packages",
    component: _9d62382a,
    name: "admin-packages"
  }, {
    path: "/admin/products",
    component: _86b01742,
    name: "admin-products"
  }, {
    path: "/admin/terms",
    component: _0dd4f11c,
    name: "admin-terms"
  }, {
    path: "/packages/confirmation",
    component: _177264a2,
    name: "packages-confirmation"
  }, {
    path: "/admin/home/home",
    component: _044649eb,
    name: "admin-home-home"
  }, {
    path: "/admin/packages/packages",
    component: _835bf1aa,
    name: "admin-packages-packages"
  }, {
    path: "/packages/confirmation/confirmation",
    component: _773a2927,
    name: "packages-confirmation-confirmation"
  }, {
    path: "/",
    component: _14c51932,
    name: "index"
  }, {
    path: "/packages/pdfGenerate/:paymentID",
    component: _7dad99b8,
    name: "packages-pdfGenerate-paymentID"
  }, {
    path: "/packages/:name",
    component: _56522b74,
    name: "packages-name"
  }, {
    path: "/packages/:name?/:name",
    component: _0c21a2f1,
    name: "packages-name-name"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
