export const AdminLayautHeader = () => import('../../components/adminLayaut/header.vue' /* webpackChunkName: "components/admin-layaut-header" */).then(c => wrapFunctional(c.default || c))
export const AdminLayautProducts = () => import('../../components/adminLayaut/products.vue' /* webpackChunkName: "components/admin-layaut-products" */).then(c => wrapFunctional(c.default || c))
export const ReusableAlerts = () => import('../../components/reusable/alerts.vue' /* webpackChunkName: "components/reusable-alerts" */).then(c => wrapFunctional(c.default || c))
export const ReusableTinymce = () => import('../../components/reusable/tinymce.vue' /* webpackChunkName: "components/reusable-tinymce" */).then(c => wrapFunctional(c.default || c))
export const AdminLayautDashboardCoupons = () => import('../../components/adminLayaut/dashboard_coupons/dashboard_coupons.js' /* webpackChunkName: "components/admin-layaut-dashboard-coupons" */).then(c => wrapFunctional(c.default || c))
export const AdminLayautDependentsDialog = () => import('../../components/adminLayaut/dependents_dialog/dependents_dialog.js' /* webpackChunkName: "components/admin-layaut-dependents-dialog" */).then(c => wrapFunctional(c.default || c))
export const AdminLayautPackageCreatePackage = () => import('../../components/adminLayaut/package_create/createPackage.js' /* webpackChunkName: "components/admin-layaut-package-create-package" */).then(c => wrapFunctional(c.default || c))
export const AdminLayautPackagesDashboardPackages = () => import('../../components/adminLayaut/packages/dashboardPackages.vue' /* webpackChunkName: "components/admin-layaut-packages-dashboard-packages" */).then(c => wrapFunctional(c.default || c))
export const AdminLayautPackagesListPackages = () => import('../../components/adminLayaut/packages/listPackages.vue' /* webpackChunkName: "components/admin-layaut-packages-list-packages" */).then(c => wrapFunctional(c.default || c))
export const AdminLayautPaymentCheckout = () => import('../../components/adminLayaut/payment_checkout/payment_checkout.js' /* webpackChunkName: "components/admin-layaut-payment-checkout" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
